import { Env } from '@env/model';
import packageJson from 'package.json';

export const environment: Env = {
  production: true,
  disableLogs: true,
  show18nSwitch: false,
  version: 'prod',
  versionFull: packageJson.version,
  urls: {
    OLD_MAIN_API: 'https://www.radioking.com/api',
    MAIN_API_V2: 'https://api.radioking.io',
    WIDGET_API: 'https://www.radioking.com/widgets/api/v1',
    WIDGET_API_V2: 'https://api.radioking.io/widget',
    WHMCS: 'https://www.radioking.com/on',
    RADIOKING_DOMAIN: 'radioking.com',
    RADIOKING_PLAY_DOMAIN: 'play.radioking.com',
    WIDGETS: 'https://www.radioking.com/widgets',
    WIDGETS_V2: 'https://widget.radioking.io',
    PLAYER_WIDGET: 'https://player.radioking.io',
    LISTEN: 'https://play.radioking.io/',
    SMARTLINK: 'https://link.radioking.com/',
    RADIO_PAGE: 'https://pageradio.radioking.com/',
    WELOVE_DOMAIN: 'welove.radio',
  },
  whmcsId: {
    Demo: 44,
    Discover: 59,
    Start: 45,
    Pro: 46,
    Business: 47,
  },
  auth: {
    isOnManagerLoginEnable: false,
    redirectUrl:
      'https://www.radioking.com/login?redirect=https:%2F%2Fmanager.radioking.com',
  },
  sentry: {
    url: 'https://5755836ebc9947999085e76c383e406f@sentry.io/1487411',
    env: 'prod',
    isEnabled: true,
  },
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US', 'fr-FR', 'en-GB'],
  cookies: {
    config: {
      domain: '.radioking.com',
      expires: 365,
      secure: true,
    },
    refreshTokenKey: 'refresh_token',
    tokenKey: 'token',
  },
  googleMapsApiKey: 'AIzaSyA-rBC6CtBzSIJ-gfVDfCviRpcrgo19TCI',
  amplitudeApiKey: '3c70d2708b3dfd47859127f15a2f734c',
  facebookPixelId: '665714114414291',
  gtm: 'GTM-MLKPSZS',
};
